import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import {Button} from "../components/button";

const AppsWrapper = styled.div`
  display: flex;
    justify-content: center;

`;
const AppWrapper = styled.div`
  padding: 40px;
  display: flex;
  .marvel-device {
  .screen {
  background: #f1f1f1;
  }
    iframe {
    padding-top:20px;
    width: 100%;
    height: calc(100% - 30px);
    }
  }
  
  @media (max-width: 768px){
    .marvel-device {
      transform: scale(0.9);
      margin-top: -50px;
    }
  }
  
  @media (max-width: 385px){
    .marvel-device {
    margin-top: -150px;
      transform: scale(0.7);
      margin-bottom: -180px;
    }
  }
  
`;

const Layoutbox = styled.div`
      @media(max-width: 768px){ 
        overflow: hidden;
      }
`;

const Row = styled.div`
  display: flex;
  padding: 40px;
  justify-content: center;
`;

const PreviewPage = () => {
    const images = useStaticQuery(graphql`
        query {
            header: file(relativePath: { eq: "preview-background.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <Layout
            className={"layout-box"}
            smallHeader={true}
            title={"BLICK IN DIE APP"}
            image={images.header.childImageSharp.fluid}
        >
            <Layoutbox className={"layout-box"}>
                <SEO title="App Vorschau"
                     description={"Machen Sie sich selbst ein Bild. So könnte ihre App aussehen - flexibel in allen Kategorien, genau wie Sie es sich wünschen."}/>
                <AppsWrapper>
                    <AppWrapper>
                        <div className="marvel-device iphone-x">
                            <div className="notch">
                                <div className="camera"></div>
                                <div className="speaker"></div>
                            </div>
                            <div className="top-bar"></div>
                            <div className="sleep"></div>
                            <div className="bottom-bar"></div>
                            <div className="volume"></div>
                            <div className="overflow">
                                <div className="shadow shadow--tr"></div>
                                <div className="shadow shadow--tl"></div>
                                <div className="shadow shadow--br"></div>
                                <div className="shadow shadow--bl"></div>
                            </div>
                            <div className="inner-shadow"></div>
                            <div className="screen">
                                <iframe id="iframe" title="iPhone Demo"
                                        src={"https://apps.mainorder.de/da-toni/"}></iframe>
                            </div>
                        </div>
                    </AppWrapper>
                </AppsWrapper>
                <Row><a target="_blank" href={"https://apps.mainorder.de/da-toni/"}><Button>im Vollbild darstellen</Button></a></Row>

            </Layoutbox>
        </Layout>
    )
};

export default PreviewPage
